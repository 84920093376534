define("discourse/plugins/discourse-patreon/discourse/connectors/admin-user-details/patreon", ["exports", "discourse/lib/ajax", "discourse/lib/url"], function (_exports, _ajax, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, context) {
      return context.siteSettings.patreon_enabled && args.model.patreon_id;
    },
    setupComponent(args, component) {
      component.set("patron_url", "https://patreon.com/members");
    },
    actions: {
      checkPatreonEmail(user) {
        (0, _ajax.ajax)((0, _url.userPath)(`${user.username_lower}/patreon_email.json`), {
          data: {
            context: window.location.pathname
          }
        }).then(result => {
          if (result) {
            const email = result.email;
            let url = "https://patreon.com/members";
            if (email) {
              url = `${url}?query=${email}`;
            }
            this.set("patreon_email", email);
            this.set("patron_url", url);
          }
        });
      }
    }
  };
});