define("discourse/plugins/discourse-patreon/discourse/models/filter-rule", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilterRule extends _rest.default {
    group_id = (() => -1)();
    reward_list = "";
  }
  _exports.default = FilterRule;
});