define("discourse/plugins/discourse-patreon/discourse/initializers/patreon", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-patreon/discourse/connectors/topic-above-footer-buttons/patreon"], function (_exports, _pluginApi, _patreon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const currentUser = api.getCurrentUser();
    api.onAppEvent("page:topic-loaded", topic => {
      if (!topic) {
        return;
      }
      const isPrivateMessage = topic.isPrivateMessage;
      if (!currentUser || isPrivateMessage) {
        return;
      }
      (0, _patreon.incrementTopicsOpened)();
    });
  }
  var _default = _exports.default = {
    name: "patreon",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initWithApi);
    }
  };
});